import assert from 'assert/assert'
import merge from 'lodash.merge'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import firebase from '../../firebase'

const db = firebase.firestore()

export const initialState = {
  blockingError: undefined,
  loginError: null,
  saving: false,
  saveError: null,
  loadingUID: null,

  // only the below fields get persisted to local storage; the rest are
  // emphemeral (and none of session gets persisted to the cloud)
  uid: null,
  displayName: undefined,
  email: undefined,
  providerId: undefined
}
function forEachNonEphemeralSessionField (f) {
  ['displayName', 'email', 'providerId', 'uid'].map(f)
}
const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    loadFromServerStart: (state, action) => {
      assert(!state.loadingUID, 'simultaneous loads not supported')
      state.loginError = null
      state.loadingUID = action.payload
      assert(state.loadingUID, 'server loading is only for logged in users')
    },
    loadFromServerFailed: (state, action) => {
      state.loadingUID = null
      if (!state.blockingError) {
        state.blockingError = action.payload
      }
    },
    loginFailed: (state, action) => {
      state.loginError = action.payload
    },
    saveStart: state => {
      state.saving = true
      state.saveError = null
    },
    saveDone: (state, action) => {
      state.saving = false
      state.saveError = action.payload
    }
  },
  extraReducers: {
    loaded: (state, action) => {
      if (action.payload) {
        assert(state.loadingUID === action.payload.session.uid)
        state.loadingUID = null
        Object.assign(state, action.payload[sessionSlice.name])
      } else {
        // no payload => clear all data
        // only override the non-ephemeral fields; the ephemeral fields store
        // application state and are not loadable
        forEachNonEphemeralSessionField(k => { state[k] = initialState[k] })
      }
    }
  }
})

export default sessionSlice.reducer
export const loginFailed = sessionSlice.actions.loginFailed

function errorToString (err) {
  console.error(err)
  return err.toString()
}

/**
 * Called whenever the user changes (login and logout, and on startup).
 * @param {firebase.User} user
 */
export const authChanged = user => async (dispatch, getState) => {
  const state = getState()
  if (state.blockingError) {
    console.log('ignoring auth changed until the app is restarted')
    return
  }
  const currentUID = state.session.uid
  const newUID = user ? user.uid : null
  if (!newUID) {
    // logged out
    if (!currentUID) {
      console.log('no change: the user is still logged out')
    } else {
      console.log('the user logged out')
      dispatch({ type: 'loaded' }) // clear the data
      saveToLocalStorage(getState)
    }
  } else {
    // logged in
    const currentStateIsLocalOnly = (currentUID === null)
    const newUserData = (
      currentStateIsLocalOnly ? pruneStateForCloud(state) : null)
    await loadDataFromServer(user, newUserData)(dispatch, getState)
  }
}

/**
 * Load data from the server. If the user does not exist on the server, then
 * create their data using curData. If curData is provided and the user did
 * exist, then curData will be stored into a backup on the server.
 *
 * Does nothing if the uid's data is returned after the session is changed to
 * a different user.
 *
 * @param {firebase.User} user the user whose data should be loaded
 * @param {Object} newUserData the data for the user, if they're new
 */
const loadDataFromServer = (user, newUserData) => async (dispatch, getState) => {
  const uid = user.uid
  dispatch(sessionSlice.actions.loadFromServerStart(uid))
  try {
    const userRef = db.collection('users').doc(uid)
    const doc = await userRef.get()
    assert(getState().session.loadingUID === uid,
      'simultaneous loads not supported')

    const data = doc.exists ? doc.data() : (newUserData || {})
    if (!doc.exists) {
      await userRef.set(data) // create the document
    } else if (newUserData && Object.keys(newUserData).length) {
      console.warn('user had local data AND cloud data')
      await userRef.update({
        oldLocalData: firebase.firestore.FieldValue.arrayUnion({
          ts: new Date().getTime(),
          data: newUserData
        })
      })
    }
    data.session = { // not stored on the server
      uid,
      displayName: user.displayName,
      email: user.email,
      providerId: user.providerData[0].providerId
    }
    dispatch({ type: 'loaded', payload: data })
    saveToLocalStorage(getState)
  } catch (error) {
    dispatch(sessionSlice.actions.loadFromServerFailed(errorToString(error)))
    if (newUserData) {
      // if we could not copy our local state to the cloud, then log out so
      // we can try again when the user logs in again
      console.error('failed to copy local state to the cloud; will log out')
      await firebase.auth().signOut()
    }
  }
}

let pendingUpdate = null

/**
 * Queues up a change to be persisted in the near future (for the current user)
 *
 * Not instant because that could create performance problems from frequent
 * changes.
 *
 * @param {Object} diff the change to apply; use
 *   firebase.firestore.FieldValue.delete() to indicate fields to be removed
 */
export const saveChangeSoon = diff => (dispatch, getState) => {
  const state = getState()
  const session = state.session
  // trying to save while loading is a bad idea; will likely load without the
  // changes we're saving
  assert(selectIsMutationAllowed(state),
    'do not try to make changes until loading is finished')
  // cannot save changes from another user until the previous changes have been
  // flushed
  assert(!pendingUpdate || session.uid === pendingUpdate.uid,
    'changes from previous session have not yet flushed')
  if (!pendingUpdate) {
    dispatch(sessionSlice.actions.saveStart())
    const saveDelayMillis = 2000 // only run at most once every 2 seconds
    pendingUpdate = {
      uid: session.uid,
      diff,
      timeout: setTimeout(() => saveNow(dispatch, getState), saveDelayMillis)
    }
  } else if (!pendingUpdate.diff) {
    pendingUpdate.diff = diff // save is in progress
  } else {
    merge(pendingUpdate.diff, diff)
  }
}

/** Helper function save, and wait until the save is complete. */
async function saveNow (dispatch, getState) {
  if (!pendingUpdate) {
    return // no changes to save
  }
  // only allow one save at a time (but multiple callers can wait for the save
  // to complete)
  if (!pendingUpdate.processing) {
    pendingUpdate.processing = new Promise((resolve, reject) => {
      performSave(getState).then(() => {
        dispatch(sessionSlice.actions.saveDone())
        resolve()
      }).catch(error => {
        dispatch(sessionSlice.actions.saveDone(errorToString(error)))
        reject(error)
      })
    })
  }
  await pendingUpdate.processing
  if (pendingUpdate) {
    // more changes were queued up; wait for them too
    return await saveNow(dispatch, getState)
  }
}

/**
 * Helper function which saves the current state and pending changes.
 *
 * Must only be called from saveNow() because we don't want parallel
 * invocations of this.
 */
async function performSave (getState) {
  saveToLocalStorage(getState)

  // save changes to the cloud if the user is logged in
  if (pendingUpdate.uid) {
    // additional changes could be queued up while we're saving (if they are,
    // we'll just save them right away for simplicity)
    while (pendingUpdate.diff) {
      const diff = pendingUpdate.diff
      pendingUpdate.diff = null
      await db.collection('users').doc(pendingUpdate.uid).update(diff)
      if (pendingUpdate.diff) {
        saveToLocalStorage(getState)
      }
    }
  }
  pendingUpdate = null
}

function saveToLocalStorage (getState) {
  const state = getState()
  const stateToSaveLocal = pruneStateForCloud(state)
  stateToSaveLocal.session = {}
  forEachNonEphemeralSessionField(k => {
    stateToSaveLocal.session[k] = state.session[k]
  })
  window.localStorage.setItem('bringspothome', JSON.stringify(stateToSaveLocal))
}

function pruneStateForCloud (state) {
  const ret = { ...state }
  delete ret.session
  return ret
}

export const logout = () => async (dispatch, getState) => {
  assert(getState().session.uid,
    'cannot logout because you are not signed in')
  if (pendingUpdate) {
    await saveNow(dispatch, getState)
  }
  await firebase.auth().signOut() // will trigger authChanged()
}

export const selectBlockingError = state => state.session.blockingError
export const selectIsLoading = state => state.session.loadingUID !== null
export const selectIsSaving = state => state.session.saving
export const selectSaveError = state => state.session.saveError
export const selectIsSignedIn = state => state.session.uid !== null
export const selectIsChangingUser = state => (
  state.session.loadingUID && state.session.uid !== state.session.loadingUID)
export const selectUserProfile = createSelector([
  state => state.session.displayName,
  state => state.session.email,
  state => state.session.providerId
], (displayName, email, providerId) => ({ displayName, email, providerId }))
export const selectIsMutationAllowed = state => {
  return !selectIsChangingUser(state) && !selectBlockingError(state)
}
