import querystring from 'querystring'
import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import firebase from '../firebase'
import sessionReducer, { authChanged, initialState, loginFailed } from '../features/session/sessionSlice'

const preloadedState = JSON.parse(window.localStorage.getItem('bringspothome') || '{}')
// most session strte is emphemeral (not in local storage)
preloadedState.session = { ...initialState, ...preloadedState.session }

const store = configureStore({
  preloadedState,
  reducer: {
    session: sessionReducer
  },
  enhancers: [Sentry.createReduxEnhancer({
    configureScopeWithState: (scope, state) => {
      scope.setContext('session', state.session)
      if (state.session.uid) {
        scope.setUser({ id: state.session.uid, email: state.session.email })
      } else {
        scope.setUser(null)
      }
    }
  })]
})

// listen for auth changes forever; never unsubscribes
firebase.auth().onAuthStateChanged(user => {
  store.dispatch(authChanged(user))
})

// check to see if the user signed in from an e-mail link
function checkForEmailLinkSignIn () {
  const originalHref = window.location.href
  if (firebase.auth().isSignInWithEmailLink(originalHref)) {
    const qs = querystring.parse(window.location.search.substring(1))
    window.history.replaceState({}, 'Bring Spot Home', '/')
    let email = qs.email // to make breeder first sign-in zero-friction
    if (!email) {
      email = window.localStorage.getItem('emailForSignIn')
    }
    if (!email) {
      // link was opened on a device other than the one which generated the
      // sign-in email; ask the user to confirm their email to prevent session
      // fixation attacks
      email = window.prompt('Please type your email to confirm login')
    }
    firebase.auth().signInWithEmailLink(email, originalHref)
      .then(result => {
        window.localStorage.removeItem('emailForSignIn')
        // result.user has the signed in user info; this will automatically
        // trigger the auth listener in store.js so we don't do anything here
      })
      .catch(error => {
        console.error(error)
        store.dispatch(loginFailed(error.toString()))
      })
  }
}
checkForEmailLinkSignIn()

export default store
